import React from "react";
import ReactHtmlParser from "html-react-parser";
import { useGetCustomCodes } from "./CustomCode.helpers";

export const CustomCodeSection = ({
    position,
    Section,
    Title,
}: {
    position: number;
    Section: ({ children }: { children: React.ReactNode }) => JSX.Element;
    Title: ({ children }: { children: React.ReactNode }) => JSX.Element;
}) => {
    let codes = useGetCustomCodes();
    codes = codes.filter(code => code.code_position?.includes(position));

    return (
        <>
            {codes.map(code => (
                <Section key={code.uuid}>
                    {code.name ? <Title>{code.name}</Title> : null}
                    {ReactHtmlParser(code.code_snippet || "")}
                </Section>
            ))}
        </>
    );
};

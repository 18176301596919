/* eslint-disable react/no-array-index-key */
import { EVENT_CATEGORISATION_TYPE, getEventListingsOfCategory } from "@Utils";
import React from "react";
import { ICategorisedEventList } from "../ICategorisedEventList";

export const CategorisedEventList = ({
    eventSectionsTitle,
    EventSectionsContainer,
    EventSection,
    allCategories,
    eventListings,
    categoriseType,
}: ICategorisedEventList) => {
    if (
        categoriseType &&
        [
            EVENT_CATEGORISATION_TYPE.SKU_TYPE,
            EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS,
        ].includes(categoriseType)
    ) {
        const showKeywords =
            categoriseType === EVENT_CATEGORISATION_TYPE.SKU_TYPE_KEYWORDS;

        return (
            <EventSectionsContainer>
                {allCategories.map(({ title: category, listingType }) => {
                    const eventListProp = getEventListingsOfCategory(
                        category,
                        categoriseType,
                        eventListings,
                        {
                            listingType,
                        }
                    );

                    if (eventListProp.length === 0) return null;
                    return (
                        <EventSection
                            key={`${category}-${listingType}`}
                            // To have unique keys even if two categories have the same name
                            eventListProp={eventListProp}
                            index={listingType}
                            category={category}
                            showKeywords={showKeywords}
                        />
                    );
                })}
            </EventSectionsContainer>
        );
    }

    if (
        categoriseType &&
        [
            EVENT_CATEGORISATION_TYPE.KEYWORDS_SKU_TYPE,
            EVENT_CATEGORISATION_TYPE.KEYWORDS,
        ].includes(categoriseType)
    ) {
        return (
            <EventSectionsContainer>
                {allCategories.map(({ title: category }, index) => {
                    const eventListProp = getEventListingsOfCategory(
                        category,
                        categoriseType,
                        eventListings
                    );
                    if (eventListProp.length === 0) return null;
                    return (
                        <EventSection
                            key={category}
                            eventListProp={eventListProp}
                            index={index}
                            category={category}
                        />
                    );
                })}
            </EventSectionsContainer>
        );
    }

    if (categoriseType === EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION) {
        const eventListProp = eventListings;
        if (eventListProp.length === 0) return null;
        return (
            <EventSectionsContainer>
                <EventSection
                    eventListProp={eventListProp}
                    category={eventSectionsTitle}
                />
            </EventSectionsContainer>
        );
    }

    return null;
};

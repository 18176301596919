import React from "react";
import { ICreatorPagesFooter } from "../FooterSection/FooterSection.interfaces";

export const TermsOfUse = ({
    handleClick,
    tnCLink,
}: {
    handleClick: ICreatorPagesFooter.IFooterSectionProps["handleClick"];
    tnCLink: ICreatorPagesFooter.IFooterSectionProps["tnCLink"];
}) => (
    <a href={tnCLink} target="_blank" rel="noreferrer" onClick={handleClick}>
        Terms of Use
    </a>
);

import React from "react";
import { ICreatorPagesFooter } from "../FooterSection/FooterSection.interfaces";

export const PrivacyPolicy = ({
    handleClick,
}: {
    handleClick: ICreatorPagesFooter.IFooterSectionProps["handleClick"];
}) => (
    <a
        href="/PrivacyPolicy"
        target="_blank"
        rel="noreferrer"
        onClick={handleClick}
    >
        Privacy Policy
    </a>
);

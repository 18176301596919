import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import React from "react";
import { EXLY_BACKEND_API_HOST } from "repoV2/constants/apis";
import { CREATOR_MISCELLANEOUS_DATA_APIS } from "repoV2/features/CreatorPages/constants/CreatorPages.apis";
import { callApi } from "repoV2/utils/common/api/callApi";
import { logError } from "repoV2/utils/common/error/error";
import { CREATOR_MISCELLANEOUS_DATA_TYPES } from "repoV2/features/CreatorPages/constants/CreatorPages.constants";
import { useSelector } from "react-redux";
import { SELECTORS } from "@Redux";
import { checkIsObjectEmpty } from "repoV2/utils/common/dataTypes/object";
import { IHost } from "@Interfaces/redux/store/IHost";
import { ICreatorPagesFooter } from "../FooterSection.interfaces";

export const useFooterSection = () => {
    const { hostName }: IHost.IStore = useSelector(SELECTORS.host);

    const [processing, setProcessing] = React.useState(false);
    const [customFooter, setCustomFooter] =
        React.useState<ICreatorPagesFooter.ICustomFooter>({});

    React.useEffect(() => {
        fetchCustomFooterData();
    }, []);

    const fetchCustomFooterData = async () => {
        try {
            if (processing) return;
            setProcessing(true);
            const apiResponse: any = await callApi({
                url: `${EXLY_BACKEND_API_HOST}/${CREATOR_MISCELLANEOUS_DATA_APIS.get}`,
                extraOptions: { appendExlyBackendHeaders: true },
                params: {
                    user__sub_domain__iexact: hostName,
                    type: CREATOR_MISCELLANEOUS_DATA_TYPES.CUSTOM_FOOTER,
                },
            });
            if (isRequestSuccessful(apiResponse?.data?.status)) {
                const [customFooterRecord] =
                    apiResponse?.data?.data?.miscellaneous_data;
                if (!checkIsObjectEmpty(customFooterRecord?.data)) {
                    setCustomFooter(customFooterRecord?.data);
                }
            }
        } catch (error) {
            logError({
                error,
                when: "fetchCustomFooterData",
                occuredAt:
                    "repoV2/features/CreatorPages/modules/FooterSection/utils/useFooterSection.ts",
            });
        } finally {
            setProcessing(false);
        }
    };

    return {
        customFooter,
    };
};

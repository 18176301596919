// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/PoweredByExly/ folder
import React from "react";
import { useFeatureAccessibility } from "repoV2/features/CreatorDetails/modules/FeatureAccessibility/utils/useFeatureAccessibility";
import { WHITELABEL_FEATURE_KEY } from "repoV2/features/CreatorPages/constants/CreatorPages.constants";
import { getCopyrightString } from "repoV2/utils/common/dataTypes/string";

export const Copyright = () => {
    const isWhitelabelActive = useFeatureAccessibility({
        featureKey: WHITELABEL_FEATURE_KEY,
    });
    if (isWhitelabelActive) return null;
    return <div>{getCopyrightString()}</div>;
};

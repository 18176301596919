import React from "react";
import { ICreatorPagesFooter } from "../../FooterSection.interfaces";

export const FooterItem = ({
    item,
}: {
    item: ICreatorPagesFooter.IFooterItem;
}) => {
    return (
        <div
            onClick={() => {
                if (item.link) window.open(item.link);
            }}
            className={item.link ? ` pointer` : ""}
        >
            {item.text}
        </div>
    );
};

import React from "react";
import { RiFacebookFill } from "@react-icons/all-files/ri/RiFacebookFill";
import { RiInstagramLine } from "@react-icons/all-files/ri/RiInstagramLine";
import { RiTwitterFill } from "@react-icons/all-files/ri/RiTwitterFill";
import { classNames } from "repoV2/utils/common/render/classNames";
import { useSocialIconClick } from "@Utils";
import { SiTiktok } from "@react-icons/all-files/si/SiTiktok";
import styles from "./TeamMemberSocialIcons.module.scss";
import { ITeamMemberSocialIcons } from "./ITeamMemberSocialIcons";

export const TeamMemberSocialIcons = ({
    socialInfo,
    template,
}: ITeamMemberSocialIcons): JSX.Element => {
    const { onSocialIconClick } = useSocialIconClick();
    const SocialIcon = ({ name, link }: { name: string; link: string }) => {
        let IconComponent = null;
        switch (name) {
            case "instagram":
                IconComponent = RiInstagramLine;
                break;
            case "facebook_name":
            case "facebook":
                IconComponent = RiFacebookFill;
                break;
            case "twitter":
                IconComponent = RiTwitterFill;
                break;
            case "tiktok":
                IconComponent = SiTiktok;
                break;

            default:
                break;
        }
        return IconComponent ? (
            <div
                className={classNames(
                    styles.socialIcon,
                    template && styles?.[template]
                )}
                onClick={e => {
                    e.stopPropagation();
                    onSocialIconClick(link);
                }}
            >
                <IconComponent />
            </div>
        ) : null;
    };

    return (
        <div
            className={classNames(
                styles.socialIconsContainer,
                template && styles?.[template]
            )}
        >
            {socialInfo.map(plaform => (
                <SocialIcon
                    name={plaform.platform}
                    link={plaform.link}
                    key={plaform.platform}
                />
            ))}
        </div>
    );
};

// if: lines of code > 300
// then: breakdown this file and move to repoV2/features/PoweredByExly/ folder
import React from "react";
import { getCopyrightString } from "repoV2/utils/common/dataTypes/string";
import { useFeatureAccessibility } from "repoV2/features/CreatorDetails/modules/FeatureAccessibility/utils/useFeatureAccessibility";
import { EXLY_LANDING_PAGE } from "repoV2/features/Common/constants/urls";
import { WHITELABEL_FEATURE_KEY } from "repoV2/features/CreatorPages/constants/CreatorPages.constants";
import styles from "./PoweredByExly.module.scss";

export const PoweredByExly = ({
    showCopyright,
    exlyClickDisabled,
    exlyTextClass,
    exlyText,
}: {
    showCopyright?: boolean;
    exlyClickDisabled?: boolean;
    exlyTextClass?: string;
    exlyText?: string;
}) => {
    const isWhitelabelActive = useFeatureAccessibility({
        featureKey: WHITELABEL_FEATURE_KEY,
    });
    if (isWhitelabelActive) return null;

    const onExlyClick = () => {
        if (exlyClickDisabled) return;
        window.open(EXLY_LANDING_PAGE);
    };

    return (
        <div className={styles.textContainerStyles}>
            Powered by{" "}
            <span
                onClick={onExlyClick}
                className={` ${
                    !exlyClickDisabled ? "pointer" : ""
                } ${exlyTextClass}`}
            >
                {exlyText}
            </span>
            {showCopyright && (
                <>
                    <br />
                    <div>{getCopyrightString()}</div>
                </>
            )}
        </div>
    );
};

PoweredByExly.defaultProps = {
    showCopyright: false,
    exlyClickDisabled: true,
    exlyTextClass: "",
    exlyText: "Exly",
};

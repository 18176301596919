import { useSelector } from "react-redux";
import { IStore } from "@Interfaces";

export const useFeatureAccessibility = ({
    featureKey,
}: {
    featureKey: string;
}) => {
    const { featureAccessibilty } = useSelector(
        (state: IStore.IState) => state.creatorDetails
    );
    return featureAccessibilty?.[featureKey];
};
